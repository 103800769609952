import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  hide() {
    document.querySelector('body').classList.add('hide-border-banner')
    document.querySelector('div.dev-on-production-banner')?.classList?.add('hide-border-banner')
    document.querySelector('div.superuser-banner')?.classList?.add('hide-border-banner')

    setTimeout(() => { this.show() }, 5000)
  }

  show () {
    document.querySelector('body').classList.remove('hide-border-banner')
    document.querySelector('div.dev-on-production-banner')?.classList?.remove('hide-border-banner')
    document.querySelector('div.superuser-banner')?.classList?.remove('hide-border-banner')
  }
}
