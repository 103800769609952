import { Controller } from "@hotwired/stimulus"

//
//
// Connects to data-controller="dependant-fields"

export default class extends Controller {
  static targets = [ "" ]

  connect() {
    this.dependantFieldSelector = '[data-controller="dependant-field"]'
    this.element.tagName == 'form' ? this.form = this.element : this.form = this.element.closest('form')
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    this.element.addEventListener('change', this.formChanged.bind(this))
  }

  dependantFieldController(element) {
    return this.application.getControllerForElementAndIdentifier(element, 'dependant-field')
  }

  setFieldsToWatch() {
    this.dependantFields = this.element.querySelectorAll(this.dependantFieldSelector)
    this.watchedFieldDetails = []
    this.fieldsToWatch = []
    this.dependantFields.forEach(dependantField => {
      let fieldController = this.dependantFieldController(dependantField)
      this.fieldsToWatch.push(fieldController.parentFieldElements())
      this.watchedFieldDetails.push({
        dependantElement: dependantField.querySelector('button,select,input'),
        url: fieldController.reRenderUrl(),
        fieldsToWatch: fieldController.parentFieldElements()
      })
    })
    this.fieldsToWatch = [...new Set(this.fieldsToWatch.flat())]
  }

  checkWatchedFields(field) {
    this.setFieldsToWatch()
    if(!this.fieldsToWatch.includes(field)){
      return
    }
    this.watchedFieldDetails.forEach(fieldDetails => {
      if(fieldDetails.fieldsToWatch.includes(field)){
        this.fetchField(fieldDetails.url, fieldDetails.dependantElement)
      }
    })
  }

  formChanged(event) {
    this.checkWatchedFields(event.target)
  }

  fetchField(url, fieldElement) {
    let formData = new FormData(this.form)
    let params = new URLSearchParams()
    formData.forEach((value, key) => {
      if(key=='_method'){
        return
      }
      params.set(key, value)
    })

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.token
      },
      credentials: 'same-origin',
      body: params
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(() => {
      this.checkWatchedFields(fieldElement)
    })
  }
}
