import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="obfuscate"

export default class extends Controller {
  static targets = [ "switch", "sensitive", "switchContainer" ]

  connect() {
    if (!this.switchTarget){
      return
    }
    this.toggleSwitchContainer()
    if (!this.switchTarget.obfuscateBound){
      this.switchTarget.obfuscateBound = true
      this.switchTarget.addEventListener('change', () => {
        this.toggleAll()
      })
    }
  }

  toggleSwitchContainer() {
    if (!this.switchContainerTarget){
      return
    }
    if (this.sensitiveTargets.length == 0){
      this.switchContainerTarget.classList.add('hide')
    } else {
      this.switchContainerTarget.classList.remove('hide')
    }
  }

  sensitiveTargetConnected(element) {
    this.toggleSwitchContainer()
    if (!this.switchTarget){
      return
    }
    this.toggle(element)
  }

  toggle(element) {
    if (this.switchTarget.checked) {
      this.reveal(element)
    } else {
      this.obscure(element)
    }
  }


  toggleAll() {
    console.log('obfuscate toggleAll')
    if (this.switchTarget.checked) {
      this.revealAll()
    } else {
      this.obscureAll()
    }
  }

  obscureAll() {
    this.sensitiveTargets.forEach((element) => {
      this.obscure(element)
    })
  }

  revealAll() {
    this.sensitiveTargets.forEach((element) => {
      this.reveal(element)
    })
  }

  obscure(element) {
    element.classList.remove('reveal')
  }

  reveal(element) {
    element.classList.add('reveal')
  }
}
