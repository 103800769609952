import { Controller } from "@hotwired/stimulus"

//
//
// Connects to data-controller="dependant"

export default class extends Controller {
  static values = {
    parentfields: String,
    url: String
  }

  connect() {
    this.form = this.element.closest('form')
  }

  parentFieldElements() {
    let fieldElements = []
    this.parentfieldsValue.split(',').forEach(parentName => {
      fieldElements.push(this.form.querySelector(`[name$="[${parentName}]"]`))
    });
    return fieldElements
  }

  reRenderUrl() {
    return this.urlValue
  }
}
